@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Quicksand:wght@300&family=Rubik:wght@300;400&display=swap');
*{
  margin: 0;
  font-family: 'Rubik', sans-serif;
  font: 16px;
  color: white;
}

body{
  background-color: #000000; 
}


.navbar{
  background-color: #0f0f0f;
  padding: 25px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 2px solid #202123;
  margin-left: auto;
  height: 50px;
  
}
.navbar h1{
  font-weight: 200;
}

.links{
  margin-left: auto;
}

.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
  font-weight: 300;
}

.navbar_currency_symbol{
  width: 40px;
  height: 40px;
  margin-right:10px;
}

.converter{
  background: rgb(15,15,15);
  background: linear-gradient(0deg, rgba(15,15,15,1) 50%, rgb(28,28,28) 100%);
  padding-top:4%;
  width: 100%; /* Set the width of the box */
  height: 550px;
  padding-bottom:10%;
}

.converter .header{
  font-weight:100;
  font-size:xx-large;
  margin-bottom: 2%;
  text-align:center;
  margin-left:25%;
  margin-right:25%;
}


.converter_main_box {
  border-radius: 15px;
  background-color: #1c1c1c;
  width: 60%;
  margin: auto;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 25px;
  max-height: 26%;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.converter_main_box.expanded {
  max-height: 100%;
}

.converter_container{
  display: grid;
  grid-template-columns: repeat(2, 1fr) 0.25fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: column;
}

.converter_container_value_label, .converter_container_to_label, .converter_container_from_label{
  margin-left:1%;
  font-size: 1.3rem;
  font-weight: 100;
  justify-self: center; 
}

.converter_container_button_switch{
  background:#2fcb9e;
  width: 50px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #2fcb9e;
  cursor: pointer;
  justify-self: center; 

  transition: filter 0.1s ease-in-out;
  
}
.converter_container_button_switch:hover{
  filter: drop-shadow(0 0 4px #78b2a9);
}

.converter_container_value_input, .converter_container_from_select, .converter_container_to_select{
  justify-self: center; 
  width: 85%;
  height: 48px;
  border: 1.5px solid #0f0f0f;
  border-radius: 6px;
  background: #0f0f0f;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 1.1rem;
  padding-left:10px;
  font-weight: 100;

}

.converter_result{
  text-align: left;
  margin-top:5%;
  margin-left:3.3%;
}
.converter_result.invisible{
  visibility: hidden;
}

.converter_result_text_1{
  font-size:1.5rem;
  font-weight:100;
  word-wrap: break-word;
}

.converter_result_text_2{
  font-size:2.8rem;
  font-weight:100;
  margin-top:2%;
  word-wrap: break-word;
  cursor: pointer;
}

.converter_copy_button{
  background: none;
  border: none;
  padding:0;
  cursor: pointer;
  transition: filter 0.1s ease-in-out;

}
.converter_copy_icon{
  height:25px;
  width:25px;
}

.converter_copy_button:hover {
  filter: drop-shadow(0 0 8px #00ffb7);
}

.converter_conversion_text1{
  font-size:1.1rem;
  font-weight:100;
  margin-top:4%;
  color: #ffffff
}
.converter_conversion_text2{
  font-size:1.1rem;
  font-weight:100;
  margin-top:1%;
  color: #ffffff
}

.converter_result_copy_container{
  display:flex;
  
}

.historical{
  background: #0f0f0f;
  padding-top:4%;
  width: 100%; /* Set the width of the box */
  height: 1200px; /* Set the height of the box */
  padding-bottom:10%;
}


.historical .header{
  font-weight:100;
  font-size:xx-large;
  margin-bottom: 1%;
  text-align:center;
  margin-left:25%;
  margin-right:25%;
}

.historical_description{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2%;
}

.historical_description_header{
  font-weight:100;
  font-size:x-large;
  text-align:center;
  margin-right:1%;
}

.historical_description_changeCurrency{
  width: 82px;
  height: 40px;
  border: 1.5px solid #949494;
  border-radius: 6px;
  background: #1c1c1c;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 1.1rem;
  padding-left:10px;
  font-weight: 100;
}

.historical_box{
  border-radius: 15px;
  background-color: #1c1c1c;
  width: 60%;
  margin: auto;
  padding-top: 35px;
  padding-bottom: 35px;
  padding-left: 30px;
  padding-right: 30px;
}

.historical_box_labels{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: start;
  padding-bottom: 10px;
  border-bottom: 1px solid #4f6961;
}


.historical_box_labels label{
  padding-left:10%;
}


.historical_box_lists_container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.historical_box_lists_container_symbol{
  color:#2fcb9e;
  padding-left:8px;
  font-size:smaller;

}

.historical_box_lists_container_coin{
  word-break:break-word;

}

.historical_box_lists_container_symbol{
  word-break:keep-all;
}

.historical_box_lists_container_price{
  word-break: break-all;
}

.historical_box_lists_container_24h, .historical_box_lists_container_30d{
  display:block;
}

.historical_box_labels_24h, .historical_box_labels_30d{
  display:block;
}

.historical_description_header{
  display:block;
}

.historical_box_lists_container div{
  padding-top:20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #4f6961;
  padding-left: 10%;
}

.historical_description_changeView{
  display:none;
}

.bottom_info{
  padding:40px;
  display: grid;
  place-items: center;
  text-align: center;
}

.bottom_socials{
  list-style: none; /* Remove the default bullet points */
  padding: 0; /* Remove any default padding */
  margin: 0; /* Remove any default margin */
  

}

.bottom_socials li {
  
  margin-bottom: 12px; /* Adjust the vertical spacing between list items */
}

.bottom_socials li a {
  text-decoration: none; /* Remove underline from links */
  color: #ffffff; /* Set the color of the links */
}

.bottom_socials li a:hover {
  color: #e3e3e3; /* Set the color of the links on hover */
}


.bottom_info p{
  margin-bottom: 12px;
}


.copy_alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 13px;
  background-color: #1c1c1c;
  border: 1px solid #00ffb7;
  border-radius: 15px;
  opacity: 0;
  transition: opacity 1s ease-out;
}


@media only screen and (max-width:992px){
  .converter{
    height: 700px; /* Set the height of the box */
  }

  .historical{
    height: auto; /* Set the height of the box */
  }

  .historical_box_lists_container{
    grid-template-columns: 1fr 1fr;
  }

  .historical_box_labels{
    grid-template-columns: 1fr 1fr;
  }

  .historical_box_lists_container_symbol{
    display:block;
    padding:0;
  }

  .historical_box_lists_container_24h, .historical_box_lists_container_30d{
    display:none;
  }

  .historical_box_labels_24h, .historical_box_labels_30d{
    display:none;
  }

  .historical_description_header{
    display:none;
  }

  .historical_description_changeView{
    display:block;
    width: 82px;
    height: 40px;
    border: 1.5px solid #949494;
    border-radius: 6px;
    background: #1c1c1c;
    box-sizing: border-box;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    font-size: 1.1rem;
    padding-left:10px;
    font-weight: 100;
    margin-left:10%;
  }





}


@media only screen and (max-width:560px){

  .navbar{
    padding: 15px;
  }
  .navbar a {
    display: none;
  }

  .converter{
    height: 900px; /* Set the height of the box */
  }

  .converter .header{
    font-size:x-large;
    padding-top:10px;
    padding-bottom:15px;
    margin-left:10px;
    margin-right:10px;
  }
  
  .converter_main_box{
    max-height:45%;
    padding-top:0;
  }
  
  .converter_container{
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr 0fr 0.5fr 0.5fr 0.5fr;
    grid-auto-flow: row;
    align-items:center;
    grid-gap:10px;
    padding-top:15px;
  }
 

  .converter_container_value_label, .converter_container_from_label, .converter_container_to_label{
    margin:0;
  }


  .converter_result_text_1{
    font-size:100%;
    word-wrap: break-word;
  }
  .converter_result_text_2{
    font-size:200%;
    word-wrap: break-word;
  }
  .converter_conversion_text1, .converter_conversion_text2{
    font-size:90%;

  }

  .converter_copy_icon{
    height:20px;
    width:20px;
  }

  .historical .header{
    font-size:x-large;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:25px;
  }

  .historical_description{
    margin-bottom:25px;
  }


  .historical_description_changeCurrency, .historical_description_changeView{
    display:block;
    width: 75px;
    height: 30px;
    font-size: 0.85rem;
    
  }
  
}

